import React from 'react';
import Box from '@mui/system/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { removeUserSession } from './Utils/Common';

export default function ButtonAppBar(props) {
  const handleLogout = () => {
    removeUserSession();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Typography variant="h6">
            RSA Dashboard
          </Typography>
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <Button
              color="inherit"
              href="/score"
            >
              Score
            </Button>
            <Button
              color="inherit"
              href="/indicators"
            >
              Indicators
            </Button>
            <Button
              color="inherit"
              href="/micro"
            >
              Micro
            </Button>
            <Button
              color="inherit"
              href="/ranking"
            >
              Ranking
            </Button>
            <Button
              color="inherit"
              href="/chart"
            >
              Chart
            </Button>
            <Button
              color="inherit"
              href="/overbought-oversold"
            >
              ObOs
            </Button>
            <Button
              color="inherit"
              href="/ml-micro"
            >
              MLMicro
            </Button>
            <Button
              color="inherit"
              href="/portfolio"
            >
              Portfolio
            </Button>
          </Box>

          <Button
            onClick={handleLogout}
            color="inherit"
            href="/login"
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
